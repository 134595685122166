import * as axios from "axios";
import QueryString from "qs";
import {store} from "../redux/store";
import {setLoading} from "../redux/slices/globalSlice";
import {Alert} from "./alert";
import {createHash} from "./globalFunctions";

export default class Api {
    constructor(timeOut = 31000, apiPrefix = true) {
        this.client = null;
        this.api_url = apiPrefix ? process.env.REACT_APP_API_URL : process.env.REACT_APP_BACKEND_URL;
        this.timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        this.timeout = timeOut
    }

    // errorHandler will compose a handleGlobally function
    errorHandler = (error) => {
        this.handleErrorStatus(error?.status ?? error?.response?.status);
        this.handleErrorMessage(error?.message);
    };

    // handling error messages
    handleErrorMessage = (message) => {
        if (message) {
            Alert.error(message.slice(0, 1).toUpperCase() + message.slice(1) + "!");
        }
    };

    // handling error status
    handleErrorStatus = (status) => {
        switch (status) {
            case 401:
            case 400:
            case "Network Error":
                window.location = "/register-phone";
                console.log("Please login to access this resource");
                break;
            case 404:
                window.location = "/notfound";
                console.log(
                    "The requested resource does not exist or has been deleted"
                );
                break;
            default:
                break;
        }
    };

    init = () => {
        let headers = {
            Accept: "application/json",
            "Content-Type": "application/json",
            Timezone: this.timeZone,
            Locale: store.getState().user.language.code
        };

        this.client = axios.create({
            baseURL: this.api_url,
            timeout: this.timeout,
            headers: headers,
        });

        this.client.interceptors.request.use(
            async (config) => {
                const user = JSON.parse(localStorage.getItem('user'))
                const _token = user?.token;
                if (_token) {
                    config.headers.Authorization = `Bearer ${_token}`;
                }
                return config;
            },
            (error) => {
                this.errorHandler(error);
                return Promise.reject(error);
            }
        );
        return this.client;
    };

    makeCall = (type, path, data) => {
        const request = this.init();
        const methods = {
            "get": request.get,
            "post": request.post,
            "put": request.put,
            "patch": request.patch,
            "delete": request.delete
        };

        const callType = methods[type.toLowerCase()];

        if (!callType) {
            throw new Error("Invalid request type: " + type);
        }

        return callType(path, data);
    };

    createCall = async (
        type,
        path,
        data = {},
        handleError = true,
        isLoading = true,
        message = "loading"
    ) => {
        const loading = {
            status: isLoading,
            message: message,
        }
        isLoading && store.dispatch(setLoading(loading));
        let result = this.makeCall(type, path, data)
            .then((res) => {
                if (res.data.success && res.data.success === true) {
                    return res;
                } else {
                    handleError && this.errorHandler(res.data);
                    return res;
                }
            })
            .catch((err) => {
                handleError && this.errorHandler(err);
            })
            .finally(() => isLoading && store.dispatch(setLoading(false)));

        return result;
    };

    registerPhone = (phone, token) => {
        phone = phone.replace(/[^\d+]/g, '')
        return this.createCall("post", "register-phone", {phone: phone, _token: createHash()}, false);
    };

    activatePhone = (data) => {
        return this.createCall("post", "activate-phone", data);
    };

    getStations = (lat, long, mapFilters, isLoading = true) => {
        return this.createCall(
            "get",
            "v2/public/stations?" +
            QueryString.stringify({
                lat: lat,
                lon: long,
                ...mapFilters,
                limit: 100,
            }),
            {},
            true,
            isLoading
        );
    };

    getStationDetail = (id) => {
        return this.createCall(
            "get",
            "v2/public/stations/" + id,
            {},
            true,
            false
        );
    };

    getStationDetailByCode = (code) => {
        return this.createCall(
            "get",
            "v2/public/connection?label=" + code,
            {},
            true,
            false
        );
    }

    getVehicles = (isLoading) => {
        return this.createCall(
            "get",
            "function/vehicle/vehicle/list",
            {},
            true,
            isLoading
        );
    };

    getVehicleByPlate = (plate) => {
        return this.createCall(
            "get",
            "function/vehicle/vehicle/get-by-plate?" +
            QueryString.stringify({
                options: {
                    parameters: {
                        plate: plate,
                    },
                    paginate: false,
                },
            }),
            {},
            false,
            false
        );
    };

    editVehicle = (plate, brand_id, model_id) => {
        return this.createCall("post", "function/vehicle/vehicle/edit", {
            options: {
                parameters: {
                    plate: plate,
                    brand_id: brand_id,
                    model_id: model_id,
                },
            },
        });
    };

    saveCar = ({plate, brand_id, model_id}) => {
        return this.createCall("post", "function/vehicle/vehicle/add", {
            options: {
                parameters: {
                    plate: plate,
                    brand_id: brand_id,
                    model_id: model_id,
                },
            },
        });
    };

    setDefaultVehicle = (id) => {
        return this.createCall(
            "post",
            "function/vehicle/vehicle/setDefaultVehicle",
            {
                options: {
                    parameters: {
                        vehicle_id: id,
                    },
                },
            }
        );
    };

    startCharge = (vehicle_id, code) => {
        return this.createCall(
            "post",
            "v2/charge/start-charge",
            {
                code: code,
                vehicleId: vehicle_id,
            },
            true,
            false
        );
    };

    stopCharge = (id) => {
        return this.createCall(
            "post",
            "v2/charge/stop-charge",
            {transactionId: id},
            true,
            false
        );
    };

    editProfilePicture = (path) => {
        let data = new FormData();
        data.append("options[parameters][profile_photo]", path[0]);
        return this.createCall(
            "post",
            "function/users/users/update-profile-photo",
            data
        );
    };
    editUser = (data) => {
        return this.createCall("post", "function/users/users/edit", data);
    };
    getUser = (isLoading) => {
        return this.createCall(
            "get",
            "function/users/users/show",
            {},
            true,
            isLoading
        );
    };
    getVehicleBrandList = () => {
        return this.createCall("get", "function/vehicle/brands/list");
    };
    getVehicleModelsList = (brandId) => {
        return this.createCall(
            "get",
            "function/vehicle/model/list?" +
            QueryString.stringify({
                options: {
                    parameters: {
                        brand_id: brandId,
                    },
                },
            })
        );
    };
    getParkingStationDetail = (id) => {
        return this.createCall(
            "get",
            "function/parking/park/detail?" +
            QueryString.stringify({
                options: {
                    parameters: {
                        park_id: id,
                    },
                    paginate: false,
                },
            }) +
            ""
        );
    };
    deleteUser = () => {
        return this.createCall("post", "function/users/users/deleteAccount");
    };
    getPages = (slug) => {
        return this.createCall("post", `pages/${slug}`, {}, true, false);
    };
    getInvoices = (isLoading, filters, page = 1, message) => {
        return this.createCall(
            "get",
            `function/payment/payment/listV2?page=${page}&` +
            QueryString.stringify({
                options: {
                    parameters: filters,
                    limit: 10
                },
            }),
            {},
            true,
            isLoading,
            message
        );
    };
    getInvoiceFilters = () => {
        return this.createCall("get", "function/payment/payment/references");
    };
    removeVehicle = (plate) => {
        return this.createCall("post", "function/vehicle/vehicle/remove", {
            options: {
                parameters: {
                    plate: plate,
                },
            },
        });
    };
    getActiveSession = (isLoading) => {
        return this.createCall(
            "post",
            "function/charging/transactions/activeCharge",
            {},
            true,
            isLoading
        );
    };
    getNotifications = () => {
        return this.createCall(
            "get",
            "notifications/get-user-notifications",
            {},
            true,
            false
        );
    };
    getInvoiceDetail = (id) => {
        return this.createCall(
            "post",
            "function/payment/payment/getPaymentV2?" +
            QueryString.stringify({
                options: {
                    parameters: {
                        payment_id: id,
                    },
                },
            })
        );
    };
    removeCard = (id) => {
        return this.createCall("post", "function/payment/payment/removeCard", {
            options: {
                parameters: {
                    card_id: id,
                },
            },
        });
    };
    createNewCard = (email, card) => {
        return this.createCall("post", "function/payment/payment/createCard", {
            options: {
                parameters: {
                    name: card.name,
                    email: email,
                    card: {
                        card_number: card.card_number.split(" ").join(""),
                        expire_month: card.expire_month,
                        expire_year: card.expire_year,
                        cvc: card.cvc,
                    },
                },
            },
        });
    };

    getDefaultCard = () => {
        return this.createCall("get", "function/payment/payment/getDefaultCard")
    }

    getPaymentMethods = () => {
        return this.createCall("get", "function/payment/payment/cards");
    };
    setDefaultCard = (id) => {
        return this.createCall("post", "function/payment/payment/setDefaultCard", {
            options: {
                parameters: {
                    payment_method_id: id,
                },
            },
        });
    };
    markAllRead = () => {
        return this.createCall("patch", "notifications/mark-notifications-as-read");
    };
    markAsReadById = (id) => {
        console.log("notifications/mark-notifications-as-read/" + id);
        return this.createCall("patch", "notifications/mark-notifications-as-read/" + id);
    };
    deleteNotification = (id) => {
        return this.createCall("patch", "notifications/mark-notifications-as-deleted/" + id);
    };
    deleteAllNotifications = () => {
        return this.createCall("patch", "notifications/mark-notifications-as-deleted");
    };

    getUserDept = () => {
        return this.createCall("post", "function/payment/payment/getUserDept")
    }
    checkUserAbleToStartSession = (checkDebt = true, checkPayment = true, checkVehicle = true) => {
        return this.createCall("post", "function/charging/transactions/check-user-availabilityV2", {
            "options": {
                "parameters": {
                    "debt": checkDebt,
                    "payment_method": checkPayment,
                    "vehicle": checkVehicle
                }
            }
        })
    }

    createCompany = (data) => {
        return this.createCall("post", "function/company/company/create-and-assign-user", {"options": {"parameters": data}})
    }

    getMyCompanies = (isLoading) => {
        return this.createCall("get", "function/company/employee/get-my-companies", {"options": {"paginate": false}}, true, isLoading)
    }

    removeCompany = (id) => {
        return this.createCall("post", "function/company/employee/remove-assigned-company", {"options": {"parameters": {"company_id": id}}})
    }

    transactionCompanyUpdate = (transaction_id, company_id) => {
        const data = {"options": {"parameters": {company_id, transaction_id}}};
        return this.createCall("post", "function/charging/transactions/transaction-company-update", data)
    }

    getFAQ = () => this.createCall("get", "get-faq");

    getConfig = (isLoading) => this.createCall("post", "config", {}, true, isLoading);

    postContactForm = (data) => {
        const formData = new FormData();
        formData.append("subject", data.title);
        formData.append("department_id", data.department_id);
        formData.append("message", data.message);
        formData.append("priority_id", 1);
        formData.append("customer_resource", "toger");
        formData.append("site_id", 1);
        data.department_slug && formData.append("department_slug", data.department_slug);
        data.file && formData.append("file", data.file);
        return  this.createCall("post", "tickets", formData);
    };

    ticketList = () => this.createCall("get", "tickets");

    ticketListForParking = () => this.createCall("get", "tickets?department_slug=park_abonelik");

    ticketDetails = id => this.createCall("get", `tickets/${id}`)

    postAnswer = (ticketId, content, file) => {
        const formData = new FormData();
        formData.append("content", content);
        formData.append("ticket_id", ticketId);
        formData.append("file", file ?? undefined);
        return this.createCall("POST", `tickets/answer/with-attachment/${ticketId}`, formData);
    }

    closeTicket = (ticketId) => this.createCall("PUT", `tickets/close/${ticketId}`)

    getWalletBalance = () => {
        return this.createCall("get", "v2/balances/get-user-balance")
    }

    getWalletPackages = () => {
        return this.createCall("get", "v2/balances/public/package-list")
    }

    getWalletTransactionHistory = () => {
        return this.createCall("get", "v2/balances/get-transaction-history-by-day")
    }

    buyBalanceWithAmount = (amount) => {
        return this.createCall("post", "v2/balances/buy-balance", {amount: amount})
    }

    buyBalanceWithPackageId = (packageId) => {
        return this.createCall("post", "v2/balances/buy-package", {packageId: packageId})
    }

    getParkList = (loading) => {
        return this.createCall("get", "v2/parking", {}, true, loading);
    }

    getUserSubscriptions = (parkId) => {
        return this.createCall("get", `v2/parking/subscriptions/${parkId}`)
    }

    addMembershipVehicle = (parkId, plate) => {
        return this.createCall("post", `v2/parking/subscriptions/vehicle`, {parkId:parkId, plate: plate})
    }

    deleteMembershipVehicle = (parkId, vehicleId) => {
        return this.createCall("delete", `v2/parking/subscriptions/vehicle/${parkId}/${vehicleId}`)
    }

    deleteMembership = (parkId,subscriptionId) => {
        return this.createCall("put", `v2/parking/subscriptions/terminate/${parkId}/${subscriptionId}`)
    }

    getChargeHistoryWithPlate = () => {
        return this.createCall("get", "charging/charging-history-plate")
    }

    getChargeHistoryWithStation = () => {
        return this.createCall("get", "charging/charging-history-station")
    }

    getStories = () =>  this.createCall("get", "stories");

    getMembershipAllPackages = (parkId) => {
        return this.createCall("get", `v2/parking/subscriptions/packages/${parkId}`)
    }

    addMembershipPackage = (parkId, packageId, plate, autoRenew, paymentMethodId, companyId) => {
        return this.createCall("post", `v2/parking/subscriptions/subscribe`, {
            packageId: packageId,
            parkId: parkId,
            plate: plate,
            automaticRenew: autoRenew,
            paymentMethodId: paymentMethodId,
            companyId: companyId,
        })
    }

    changePaymentMethod = (code, transactionId, paymentType) => {
        return this.createCall('post','v2/charge/change-payment-method',{code, transactionId, paymentType})
    }

    resendMail = () => {
        return this.createCall('post','function/users/users/resend-activation-mail')
    }

    getParks = (lat, long, isLoading = true) => {
        return this.createCall(
            "get",
            "v2/public/parks?" +
            QueryString.stringify({
                lat: lat,
                lon: long,
            }),
            {},
            true,
            isLoading
        );
    }
    getParkDetail = (id) => {
        return this.createCall(
            "get",
            "v2/public/parks/" + id,
            {},
            true,
            false
        );
    }

}

export const api = new Api();
