import React from 'react';
import {TGButton, TGText} from "../TGElements";
import {IonModal} from "@ionic/react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {setPaymentMethodModal} from "../../redux/slices/membershipSlice";
import {CardWrapper} from "../ChangePaymentMethodModal";
import {addDays, format} from "date-fns";
import {api, env, getUserSubscription} from "../../helpers";
import ChangePaymentMethodModal from "./ChangePaymentMethodModal";
import {useHistory} from "react-router-dom";
import tr from "date-fns/locale/tr";
import {Alert} from "../../helpers/alert";

const ParkPaymentMethodModal = () => {
    const {
        paymentMethodModal:isOpen,
        selectedPackage,
        paymentType,
        selectedPark,
        membershipPlate
    } = useSelector(state => state.membership)
    const {selectedCompanyId} = useSelector(state => state.session)
    const {paymentMethods} = useSelector(state => state.user)
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();
    const activeMethod = paymentType === 3 ? "bank" : "card";
    const today = new Date()
    const startHandler = () => {
        if (!paymentMethods) {
            Alert.error(t('no-registered-cards-title'))
            return
        }

        api.addMembershipPackage(selectedPark, selectedPackage.id,membershipPlate, false, paymentType, selectedCompanyId)
          .then((res) => {
              if (res.data.success) {
                  dispatch(setPaymentMethodModal(false))
                  getUserSubscription(selectedPark)
                  history.goBack()
              }
          })
    }

    return (
        <IonModal
            className={"TGModal"}
            isOpen={isOpen}
            breakpoints={[0, 1]}
            initialBreakpoint={1}
            onDidDismiss={() => dispatch(setPaymentMethodModal(false))}
            style={{'--height': 'auto'}}
        >
            <div>
                <div className={"PaymentMethodsModal"}>
                    <div className={"TGPageContent ion-padding-start ion-padding-end"}>
                        <TGText margin={"30px 0 6px 0"} fontWeight={600} textTransform={"none"}>
                            {t('SelectPaymentMethod.payment-type')}
                        </TGText>

                        <CardWrapper type={paymentType}/>


                        {activeMethod === "bank" && selectedPackage?.iban && (
                            <div className={"ibanWrapper"}>
                                <TGText fontWeight={600} fontSize={16}>{t('invoice-detail')}</TGText>
                                <p
                                    style={{
                                        fontSize: "16px",
                                        color: "var(--tg-secondary-text)"
                                    }}
                                    dangerouslySetInnerHTML={{__html:selectedPackage?.iban}}
                                />
                                <div style={{
                                    borderBottom: '1px solid var(--tg-gray-6)',
                                    marginBottom: '20px'
                                }}/>
                            </div>
                        )}


                        {selectedPackage && (
                            <div className={'BottomBtn'}>
                                <div>
                                    <div className={"SubscriptionInfo"}>
                                        <span>{t('membership-start-date')}</span>
                                        <span>: </span>
                                        <b>{format(today, 'dd MMM yyy - HH:mm',{locale:tr})}</b>
                                    </div>
                                    <div className={"SubscriptionInfo"}>
                                        <span>{t('membership-end-date')}</span>
                                        <span>: </span>
                                        <b>{format(addDays(today, selectedPackage.days), 'dd MMM yyy - HH:mm',{locale:tr})}</b>
                                    </div>
                                </div>

                                <div className={"TotalPrice"}>
                                    <span>{t('total')}</span><b>{selectedPackage?.cost + env("CURRENCY_SYMBOL")}</b>
                                </div>
                            </div>
                        )}

                        <TGButton
                            onButtonClick={startHandler}
                            backgroundColor={"var(--tg-primary-text)"}
                            backgroundActiveColor={"var(--tg-black-active)"}
                            additionalStyle={{
                                maxHeight: "50px",
                                fontSize: "16px",
                                width: "100%",
                                textTransform: "none",
                                margin: "30px 0 30px",
                            }}
                        >
                            {t('membership-request')}
                        </TGButton>
                    </div>
                </div>
            </div>
            <ChangePaymentMethodModal />
        </IonModal>
    );
};

export default ParkPaymentMethodModal;